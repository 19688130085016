import * as Data from "./data";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./result.scss";
import "./result.css";
import { apiConfig } from "../../../settings/constants";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Banner from "./banner";
import callApi from "../../../utils/callApi";
export default function Result() {
  const dataResult = sessionStorage.getItem("_t")
    ? JSON.parse(sessionStorage.getItem("_t")).data
    : null;
  const [dataOverView, setDataOverView] = useState(null);
  const [dataConcluded, setDataConcluded] = useState(null);
  const location = useLocation();
  console.log(location);
  useEffect(() => {
    document.title = "Kết quả soi da";
    callApiRenderOverView();
    callApiRenderConcluded();
    if (dataResult) {
      function jsonPath(obj, expr, arg) {
        var P = {
          resultType: (arg && arg.resultType) || "VALUE",
          result: [],
          normalize: function (expr) {
            var subx = [];
            return expr
              .replace(/[\['](\??\(.*?\))[\]']/g, function ($0, $1) {
                return "[#" + (subx.push($1) - 1) + "]";
              })
              .replace(/'?\.'?|\['?/g, ";")
              .replace(/;;;|;;/g, ";..;")
              .replace(/;$|'?\]|'$/g, "")
              .replace(/#([0-9]+)/g, function ($0, $1) {
                return subx[$1];
              });
          },
          asPath: function (path) {
            var x = path.split(";"),
              p = "$";
            for (var i = 1, n = x.length; i < n; i++)
              p += /^[0-9*]+$/.test(x[i])
                ? "[" + x[i] + "]"
                : "['" + x[i] + "']";
            return p;
          },
          store: function (p, v) {
            if (p)
              P.result[P.result.length] =
                P.resultType == "PATH" ? P.asPath(p) : v;
            return !!p;
          },
          trace: function (expr, val, path) {
            if (expr) {
              var x = expr.split(";"),
                loc = x.shift();
              x = x.join(";");
              if (val && val.hasOwnProperty(loc))
                P.trace(x, val[loc], path + ";" + loc);
              else if (loc === "*")
                P.walk(loc, x, val, path, function (m, l, x, v, p) {
                  P.trace(m + ";" + x, v, p);
                });
              else if (loc === "..") {
                P.trace(x, val, path);
                P.walk(loc, x, val, path, function (m, l, x, v, p) {
                  typeof v[m] === "object" &&
                    P.trace("..;" + x, v[m], p + ";" + m);
                });
              } else if (/,/.test(loc)) {
                // [name1,name2,...]
                for (
                  var s = loc.split(/'?,'?/), i = 0, n = s.length;
                  i < n;
                  i++
                )
                  P.trace(s[i] + ";" + x, val, path);
              } else if (/^\(.*?\)$/.test(loc))
                // [(expr)]
                P.trace(
                  P.eval(loc, val, path.substr(path.lastIndexOf(";") + 1)) +
                    ";" +
                    x,
                  val,
                  path
                );
              else if (/^\?\(.*?\)$/.test(loc))
                // [?(expr)]
                P.walk(loc, x, val, path, function (m, l, x, v, p) {
                  if (P.eval(l.replace(/^\?\((.*?)\)$/, "$1"), v[m], m))
                    P.trace(m + ";" + x, v, p);
                });
              else if (/^(-?[0-9]*):(-?[0-9]*):?([0-9]*)$/.test(loc))
                // [start:end:step]  phyton slice syntax
                P.slice(loc, x, val, path);
            } else P.store(path, val);
          },
          walk: function (loc, expr, val, path, f) {
            if (val instanceof Array) {
              for (var i = 0, n = val.length; i < n; i++)
                if (i in val) f(i, loc, expr, val, path);
            } else if (typeof val === "object") {
              for (var m in val)
                if (val.hasOwnProperty(m)) f(m, loc, expr, val, path);
            }
          },
          slice: function (loc, expr, val, path) {
            if (val instanceof Array) {
              var len = val.length,
                start = 0,
                end = len,
                step = 1;
              loc.replace(
                /^(-?[0-9]*):(-?[0-9]*):?(-?[0-9]*)$/g,
                function ($0, $1, $2, $3) {
                  start = parseInt($1 || start);
                  end = parseInt($2 || end);
                  step = parseInt($3 || step);
                }
              );
              start =
                start < 0 ? Math.max(0, start + len) : Math.min(len, start);
              end = end < 0 ? Math.max(0, end + len) : Math.min(len, end);
              for (var i = start; i < end; i += step)
                P.trace(i + ";" + expr, val, path);
            }
          },
          eval: function (x, _v, _vname) {
            try {
              return $ && _v && eval(x.replace(/@/g, "_v"));
            } catch (e) {
              throw new SyntaxError(
                "jsonPath: " +
                  e.message +
                  ": " +
                  x.replace(/@/g, "_v").replace(/\^/g, "_a")
              );
            }
          },
        };

        var $ = obj;
        if (
          expr &&
          obj &&
          (P.resultType == "VALUE" || P.resultType == "PATH")
        ) {
          P.trace(P.normalize(expr).replace(/^\$;/, ""), obj, "$");
          return P.result.length ? P.result : false;
        }
      }
      var resultModule = (function () {
        const IMAGE_INFO_SELECTOR = "$.facedata.['image_info']";
        const SKIN_SPOT_SELECTOR =
          "$.facedata.specialResult.data[*].data[?(@.key == 'SkinSpot')]";
        const SKIN_ACNE_SELECTOR =
          "$.facedata.specialResult.data[*].data[?(@.key == 'SkinAcne')]";
        const SKIN_PIMPLE_SELECTOR =
          "$.facedata.specialResult.data[*].data[?(@.key == 'SkinPimple')]";
        const SKIN_BLACKHEADS_SELECTOR =
          "$.facedata.specialResult.data[*].data[?(@.key == 'SkinBlackHeads')]";
        const SKIN_MOLE_SELECTOR =
          "$.facedata.generalResult.data[*].data[?(@.key == 'SkinMole')]";
        // "$.data.facedata.generalConclusion.data[*].data[?(@.key == 'SkinMole')]";

        const SKIN_DRAWERS = [
          {
            selector: SKIN_SPOT_SELECTOR,
            checkboxId: "myCheckSpot",
            plotContainerId: "spotContainer",
            plotColor: "#FEA500",
            status: "status_cb1",
            number: "number_cb1",
          },
          {
            selector: SKIN_ACNE_SELECTOR,
            checkboxId: "myCheckAcne",
            plotContainerId: "acneContainer",
            plotColor: "#FFFF00",
            status: "status_cb2",
            number: "number_cb2",
          },
          {
            selector: SKIN_PIMPLE_SELECTOR,
            checkboxId: "myCheckPimple",
            plotContainerId: "pimpleContainer",
            plotColor: "##00A853",
            status: "status_cb3",
            number: "number_cb3",
          },
          {
            selector: SKIN_BLACKHEADS_SELECTOR,
            checkboxId: "myCheckBlackhead",
            plotContainerId: "blackHeadContainer",
            plotColor: "#FF50AF",
            status: "status_cb4",
            number: "number_cb4",
          },
          {
            selector: SKIN_MOLE_SELECTOR,
            checkboxId: "myCheckMole",
            plotContainerId: "moleContainer",
            plotColor: "#FE0000",
            status: "status_cb5",
            number: "number_cb5",
          },
        ];

        var dataJson = null;

        startup();
        return {
          toggleAcne: toggleAcne,
          toggleBlackhead: toggleBlackhead,
          toggleMole: toggleMole,
          togglePimple: togglePimple,
          toggleSpot: toggleSpot,
          startup: startup,
        };

        function startup() {
          try {
            dataJson = dataResult;
          } catch (e) {
            console.error(e);
          }
          dataJson && showResult(dataJson);
          const imageSizeChangedObserver = new ResizeObserver(function () {
            dataJson && renderSkinIssues(dataJson);
          });
          imageSizeChangedObserver.observe(
            document.getElementById("imageResult")
          );
        }

        function showResult(dataJSON) {
          dataJSON.facedata.generalConclusion.data =
            dataJSON.facedata.generalConclusion.data.slice(0, 2);
          renderSkinIssues(dataJSON);
        }

        function renderSkinIssues(data) {
          const imageInfos = jsonPath(data, IMAGE_INFO_SELECTOR);
          const imageInfo = imageInfos && imageInfos[0];
          if (!imageInfo) return;
          const img = document.getElementById("imageResult");
          img.src = location.state.imageUrl;

          const imagePlottedWidth = img.width;
          const originalWidth = imageInfo.width;
          const scaleRatio = imagePlottedWidth / originalWidth;

          clearDrawData();

          SKIN_DRAWERS.forEach(function (item) {
            const skinItems = jsonPath(data, item.selector);
            const skinItem = skinItems && skinItems[0];

            if (skinItem && skinItem.value > 0) {
              if (document.getElementById(item.status)) {
                document.getElementById(item.status).style.display = "block";
              }
              document
                .getElementById(item.checkboxId)
                .setAttribute("checked", true);

              drawData(
                skinItem.drawArr,
                item.plotContainerId,
                item.plotColor,
                scaleRatio
              );
              let inner = document.getElementById(item.number);
              if (inner) inner.innerHTML = `(${skinItem.drawArr.length})`;
            } else {
            }
          });
        }
        function drawData(arr, itemId, color = "red", scaleRatio = 1) {
          if (arr && arr.length > 0) {
            for (var i = 0; i < arr.length; i++) {
              renderDivItem(itemId, arr[i], scaleRatio, color);
            }
          }
        }

        function clearDrawData() {
          document
            .querySelectorAll(".ai-skin__result-plot__box")
            .forEach(function (item) {
              item.remove();
            });
        }

        function renderDivItem(itemId, data, scaleRatio = 1, color = "red") {
          var div = document.createElement("div");

          div.style.width = data.width * scaleRatio + "px";
          div.style.height = data.height * scaleRatio + "px";
          div.style.top = data.top * scaleRatio + "px";
          div.style.left = data.left * scaleRatio + "px";
          div.style.color = color;
          div.style.borderColor = color;

          div.className = "ai-skin__result-plot__box";
          let el = document.getElementById(itemId);
          el.appendChild(div);
        }

        function toggleSpot() {
          toggleSkinIssue("myCheckSpot", "spotContainer");
        }

        function toggleAcne() {
          toggleSkinIssue("myCheckAcne", "acneContainer");
        }

        function togglePimple() {
          toggleSkinIssue("myCheckPimple", "pimpleContainer");
        }

        function toggleBlackhead() {
          toggleSkinIssue("myCheckBlackhead", "blackHeadContainer");
        }

        function toggleMole() {
          toggleSkinIssue("myCheckMole", "moleContainer");
        }

        function toggleSkinIssue(toggleId, containerId) {
          var checkBox = document.getElementById(toggleId);
          var plotContainer = document.getElementById(containerId);
          if (checkBox.checked == true) {
            plotContainer.style.display = "block";
          } else {
            plotContainer.style.display = "none";
          }
        }
      })();
    } else {
      window.location.href = "/";
    }
  }, []);
  function toggleSkin(toggleId, containerId) {
    var checkBox = document.getElementById(toggleId);
    var plotContainer = document.getElementById(containerId);
    if (checkBox && plotContainer && checkBox.checked == true) {
      plotContainer.style.display = "block";
    } else {
      plotContainer.style.display = "none";
    }
  }
  function toggleSkinSpot() {
    toggleSkin("myCheckSpot", "spotContainer");
  }

  function toggleSkinAcne() {
    toggleSkin("myCheckAcne", "acneContainer");
  }

  function toggleSkinPimple() {
    toggleSkin("myCheckPimple", "pimpleContainer");
  }

  function toggleSkinBlackhead() {
    toggleSkin("myCheckBlackhead", "blackHeadContainer");
  }

  function toggleSkinMole() {
    toggleSkin("myCheckMole", "moleContainer");
  }
  const renderInfo = (data) => {
    let dataSpecialResult = data?.facedata?.generalResult;
    var itemRender = null;
    itemRender = dataSpecialResult?.data.map((item, index) => {
      let resultText = item.data;
      return (
        <div key={index} className="tab-info">
          <div className="left">
            <img src={`/assets/images/skincare/face${index + 1}.png`} alt="" />
          </div>
          <div className="right">
            <div className="box-content">
              <div className="title">{item.title?.vi}</div>
              <div className="text-result">
                {resultText.map((item, index) => {
                  return <span key={index}>{item.valueVI}</span>;
                })}
              </div>
            </div>
          </div>
        </div>
      );
    });
    return itemRender;
  };
  const renderConclusion = (conclusions, faceAttitude) => {
    var itemRender = null;
    var index = 1;
    var img;
    itemRender = conclusions?.data.map((ketLuanTungPhanItem, i) => {
      // if (i === 0) {
      //   img = faceAttitude.faceLeft;
      // }
      // if (i === 1) {
      //   img = faceAttitude.faceRight;
      // }
      // if (i === 2) {
      //   img = faceAttitude.eyeRight;
      // }
      // if (i === 3) {
      //   img = faceAttitude.faceLeftOriginal;
      // }
      // if (i === 4) {
      //   img = faceAttitude.faceRightOriginal;
      // }
      let li = null;
      var dataList = ketLuanTungPhanItem.data;
      li = dataList.map((item, index) => {
        var valueItemText = item.valueVI;
        valueItemText = valueItemText.split(":");
        var textValue = valueItemText[1];
        if (textValue.includes("%")) {
          let replacePercent = textValue.replace("%", "");
          let valueParser = parseFloat(replacePercent).toFixed(3);
          if (valueParser > 0) {
            return (
              <li className="content-paragraph">
                {valueItemText[0]} :{" "}
                <span className="spanTextResult">{valueParser} %</span>
              </li>
            );
          } else {
            return (
              <li className="content-paragraph">
                {valueItemText[0]} : <span className="spanTextResult">0</span>
              </li>
            );
          }
        } else {
          return (
            <li className="content-paragraph">
              {valueItemText[0]} :{" "}
              <span className="spanTextResult">{textValue}</span>
            </li>
          );
        }
      });
      var html = (
        <div class="result-item">
          <div class="avatar-image slide-left-banner">
            <img src={`/assets/images/result-${i + 1}.png`} alt="img" />
          </div>
          <div class="content-description slide-right-banner">
            <h6 class="title-bold">{ketLuanTungPhanItem.title.vi}</h6>
            <ul>{li}</ul>
          </div>
        </div>
      );
      return html;
    });
    return itemRender;
  };
  const renderOverview = (data) => {
    let itemRender = null;
    var index = 0;
    itemRender = data?.map((item, i) => {
      index++;
      let img;
      if (item.image) {
        img = item.image;
      } else {
        if (index % 2 !== 0) {
          img = "/assets/images/kt-1.png";
        } else {
          img = "/assets/images/kt-2.png";
        }
      }
      if (item.title) {
        if (index % 2 !== 0) {
          return (
            <div class="description-tuvan">
              <div class="image-demo">
                <img
                  src={img}
                  onError={(e) => (e.target.src = "/assets/images/kt-1.png")}
                  alt=""
                />
              </div>
              <div class="content-tuvan right">
                <h6>{item.title}</h6>
                <span class="content-paragraph">{item.des}</span>
              </div>
            </div>
          );
        } else {
          return (
            <div class="description-tuvan">
              <div class="content-tuvan left">
                <h6>{item.title}</h6>
                <span class="content-paragraph">{item.des}</span>
              </div>
              <div class="image-demo">
                <img
                  src={img}
                  alt=""
                  onError={(e) => (e.target.src = "/assets/images/kt-2.png")}
                />
              </div>
            </div>
          );
        }
      }
    });
    return itemRender;
  };
  const callApiRenderOverView = async () => {
    await callApi
      (apiConfig.EP_GENERAL_ADVICE_INFO, "POST", {
        filter: dataResult?.facedata?.hintResult,
      })
      .then((res) => {
        setDataOverView(res.data.data);
        console.log("overview", res.data.data);
      });
  };
  const callApiRenderConcluded = async () => {
    await callApi
      .post(apiConfig.EP_DETAIL_CONCLUSION_INFO, "POST" , {
        filter: dataResult?.facedata?.hintResult,
      })
      .then((res) => {
        setDataConcluded(res.data.data);
        console.log("concluded", res.data.data);
      });
  };
  const renderConcluded = (data) => {
    let itemRender = null;
    var index = 0;
    itemRender = data?.map((item, i) => {
      index++;
      let img;
      if (item.image) {
        img = item.image;
      } else {
        if (index % 2 !== 0) {
          img = "/assets/images/tv-1.png";
        } else {
          img = "/assets/images/tv-2.png";
        }
      }
      if (item.title) {
        if (index % 2 !== 0) {
          return (
            <div className="description-tuvan">
              <div className="image-demo">
                <img
                  src={img}
                  alt=""
                  onError={(e) => (e.target.src = "/assets/images/tv-1.png")}
                />
              </div>
              <div className="content-tuvan right">
                <h6>{item.title}</h6>
                <span className="content-paragraph">{item.des}</span>
              </div>
            </div>
          );
        } else {
          return (
            <div className="description-tuvan">
              <div className="content-tuvan left">
                <h6>{item.title}</h6>
                <span className="content-paragraph">{item.des}</span>
              </div>
              <div className="image-demo">
                <img
                  src={img}
                  alt=""
                  onError={(e) => (e.target.src = "/assets/images/tv-2.png")}
                />
              </div>
            </div>
          );
        }
      }
    });
    return itemRender;
  };
  return (
    <>
      <div className="container-layout">
        <div className="page-result">
          <div className="title-page">KẾT QUẢ PHÂN TÍCH</div>
          <div className="flex-center">
            <img
              className="img-section"
              src="/assets/images/step3.png"
              alt=""
            />
          </div>
          <div className="ai-skin__result-top" id="ai-skin-care">
            <div className="ai-skin__result-image ai-skin__image--cropped">
              <img alt="result" id="imageResult" src="" />
              <div id="spotContainer" className="ai-skin__result-plot"></div>
              <div
                id="blackHeadContainer"
                className="ai-skin__result-plot"
              ></div>
              <div id="pimpleContainer" className="ai-skin__result-plot"></div>
              <div id="acneContainer" className="ai-skin__result-plot"></div>
              <div id="moleContainer" className="ai-skin__result-plot"></div>
            </div>
            <div className="ai-skin__result-checkContainer">
              <div className="rowcolum1">
                <label className="ai-skin__result-checkbox">
                  <span className="ai-skin__result-checkbox-input">
                    <input
                      type="checkbox"
                      id="myCheckSpot"
                      onClick={toggleSkinSpot}
                    />
                    <span className="ai-skin__result-checkmark">
                      <span></span>
                    </span>
                  </span>
                  <span className="ai-skin__result-checkbox-label">
                    Đốm tàn nhan
                  </span>
                </label>
                <label className="ai-skin__result-checkbox">
                  <span className="ai-skin__result-checkbox-input">
                    <input
                      type="checkbox"
                      id="myCheckAcne"
                      onClick={toggleSkinAcne}
                    />
                    <span className="ai-skin__result-checkmark">
                      <span></span>
                    </span>
                  </span>
                  <span className="ai-skin__result-checkbox-label">
                    Mụn trứng cá
                  </span>
                </label>
              </div>

              <div className="rowcolum1 column2">
                <label className="ai-skin__result-checkbox">
                  <span className="ai-skin__result-checkbox-input">
                    <input
                      type="checkbox"
                      id="myCheckPimple"
                      onClick={toggleSkinPimple}
                    />
                    <span className="ai-skin__result-checkmark">
                      <span></span>
                    </span>
                  </span>
                  <span className="ai-skin__result-checkbox-label">
                    Mụn sưng viêm
                  </span>
                </label>
                <label className="ai-skin__result-checkbox">
                  <span className="ai-skin__result-checkbox-input">
                    <input
                      type="checkbox"
                      id="myCheckBlackhead"
                      onClick={toggleSkinBlackhead}
                    />
                    <span className="ai-skin__result-checkmark">
                      <span></span>
                    </span>
                  </span>
                  <span className="ai-skin__result-checkbox-label">
                    Mụn đầu đen
                  </span>
                </label>
              </div>
              <div className="rowcolum1 column3">
                <label className="ai-skin__result-checkbox">
                  <span className="ai-skin__result-checkbox-input">
                    <input
                      type="checkbox"
                      id="myCheckMole"
                      onClick={toggleSkinMole}
                    />
                    <span className="ai-skin__result-checkmark">
                      <span></span>
                    </span>
                  </span>
                  <span className="ai-skin__result-checkbox-label">
                    Nốt ruồi
                  </span>
                </label>
              </div>
            </div>
            <div className="content">
              <h6>Bây giờ hãy khám phá các</h6>
              <h6>dấu hiệu lão hoá da của bạn một cách chi tiết</h6>
            </div>
          </div>
          <div id="thongtintongquan">
            <div className="title-section">Thông tin tổng quan</div>
            {renderInfo(dataResult)}
            <div style={{ padding: "20px" }}>
              <div className="notice">
                <p>
                  Chúng tôi muốn bạn có thể dễ dàng nắm bắt mọi thông tin và
                  hiểu hơn trước khi vào sâu chi tiết
                </p>
              </div>
            </div>
          </div>

          <div id="ketluantungphan">
            <div className="title-section">
              Các vấn đề cần lưu ý nhất trên làn da
            </div>
            <div className="desc-section">
              Hiểu chi tiết hơn về làn da giúp bạn biết cách chăm sóc và có làn
              da tuyệt vời nhất
            </div>
            <div className="list_conclusion_section">
              {renderConclusion(
                dataResult?.facedata?.specialResult,
                dataResult?.faceAttitude
              )}
            </div>
          </div>

          <div id="overview">
            <div className="title-box">
              <div className="box-color"></div>
              <div className="title-large">Tư vấn tổng quát</div>
            </div>
            <div className="list__overview">
              {dataOverView && dataOverView.length > 0
                ? renderOverview(dataOverView)
                : null}
            </div>
          </div>
          {/* <div id="concluded">
            <div className="title-box">
              <div className="box-color"></div>
              <div className="title-large">Kết luận chi tiết</div>
            </div>
            <div className="list__concluded">
              {dataConcluded && dataConcluded.length > 0
                ? renderConcluded(dataConcluded)
                : null}
            </div>
          </div> */}
          <div className="btn-suggest">
            <Link to="/ket-qua-soi-da/san-pham-goi-y">
              <img
                src="/assets/images/arrow.png"
                className="img-arrow"
                alt=""
              />
              GỢI Ý CHĂM SÓC TỪ CHUYÊN GIA
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
