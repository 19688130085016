import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import "./root.css";
import "./css/all.css";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import myReducer from "./redux/reducer";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ENV } from "./settings/constants";
const Store = createStore(myReducer, applyMiddleware(thunk));

const SENTRY_DEV =
  "http://ec4974e4d4b94e7195af8daf8f45350a@167.179.105.193:9000/15";
const SENTRY_PRODUCT =
  "http://f4a7b867715c402da0a3a0fff4d355a4@167.179.105.193:9000/10";

const getSentryUrl = (env) => {
  if(env === "production"){
    return SENTRY_PRODUCT
  }else {
    return SENTRY_DEV
  }
}


Sentry.init({
  dsn: getSentryUrl(ENV),
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
