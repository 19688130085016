import React, { useEffect } from "react";
import "./banner.scss";
import { gsap } from "gsap";
import Flickity from "react-flickity-component";

export default function Banner() {
  const flickityOptions = {
    initialIndex: 0,
  };
  useEffect(() => {
    // 
    var elements = document.getElementsByClassName("txt-rotate");
    for (var i = 0; i < elements.length; i++) {
      var toRotate = elements[i].getAttribute("data-rotate");
      var period = elements[i].getAttribute("data-period");
      if (toRotate) {
        new TxtRotate(elements[i], JSON.parse(toRotate), period);
      }
    }
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML =
      ".txt-rotate > .wrap { border-right: 0.08em solid var(--color-blue-main) }";
    document.body.appendChild(css);
    const item = document.querySelectorAll(".box-about-item");
    if (item) {
      gsap.from(item, {
        duration: 1,
        y: 80,
        opacity: 0,
        stagger: 0.5,
        scrollTrigger: {
          trigger: "#list-box-about-item",
        },
      });
    }
    const imgBanner = document.querySelectorAll(".banner-img-effect");
    // if (imgBanner) {
    //   gsap.from(imgBanner, {
    //     duration: 1,
    //     x: 200,
    //     ease: 'bounce',
    //   });
    // };
  });
  var TxtRotate = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 100) || 4000;
    this.txt = "";
    this.tick();
    this.isDeleting = false;
  };

  TxtRotate.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

    var that = this;
    var delta = 150 - Math.random() * 100;

    if (this.isDeleting) {
      delta /= 2;
    }
    if (!this.isDeleting && this.txt === fullTxt) {
      // delta = this.period;
      delta = 2000;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === "") {
      this.isDeleting = false;
      this.loopNum++;
      delta = 2000;
    }
    setTimeout(function () {
      that.tick();
    }, delta);
  };
  return (
    <>
      <div className="banner-layout">
        <div className="container-layout height">
          <div className="flex">
            <div className="banner-img">
              <Flickity
                className={""} // default ''
                elementType={"div"} // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                static // default false
                autoPlay={true}
              >
                <img
                  className="banner-img-effect"
                  src="/assets/images/Banner_600x113_01.jpg"
                  alt=""
                />
                <img
                  className="banner-img-effect"
                  src="/assets/images/Banner_600x113_02.jpg"
                  alt=""
                />
                <img
                  className="banner-img-effect"
                  src="/assets/images/Banner_600x113_03.jpg"
                  alt=""
                />
              </Flickity>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
