import Header from "../../../components/Layout/Header";
function HeaderRouter() {
  return (
    <>
      <Header />
    </>
  );
}

export default HeaderRouter;
