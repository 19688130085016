import "./modal.scss";
export default function ModalTip() {
  const hideTips = () => {
    const modal = document.querySelector(".status-tip");
    modal.classList.add("hide");
  };
  return (
    <div className="status-tip">
      <div className="modal-layout">
        <div class="tip_content">
          <span class="title">
            Ứng dụng sẽ chụp gương mặt của bạn. Sau đây là một số hướng dẫn để
            có những bức ảnh chuẩn xác nhất
          </span>
          <ol class="body">
            <li>Giữ chặt đIện thoạI trong khi chụp</li>
            <li>Tẩy trang &amp; làm sạch da trước khi sử dụng ứng dụng</li>
            <li>Cột/búI tóc lên cao và cởI mắt kính (nếu có)</li>
            <li>
              Kiểm tra ánh sáng của phòng sử dụng camera trước để phân tích làn
              da bạn
            </li>
          </ol>
          <div class="button-hide">
            <button type="button" onClick={hideTips}>
              TÔI ĐÃ HIỂU
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
