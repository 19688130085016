import React from "react";

import Footer from "../../../components/Layout/Footer";
function FooterRouter() {
  return (
    <>
      <Footer />
    </>
  );
}
export default FooterRouter;
