import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import routes from "./routes";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          {routes
            ? routes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    element={route.component}
                  />
                );
              })
            : null}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
