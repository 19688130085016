import { useEffect } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import ProductComponent from "../../components/Router/ProductSuggest";
import Banner from '../../components/Router/ProductSuggest/banner';
function Product() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });
  return (
    <>
      <div style={{ minHeight: "100vh" }}>
        <Banner />
        <Header />
        <ProductComponent />
      </div>
      {/* <Footer /> */}
    </>
  );
}
export default Product;
