import Home from "./pages/Home";
import Result from "./pages/Result";
import ProductSuggest from "./pages/Product";
import ErrorScanSkin from "./pages/ErrorScanSkin";
const routes = [
  { path: "/", component: <Home />, exact: true },
  { path: "/:user_id", component: <Home />, exact: false },

  {
    path: "/ket-qua-soi-da/san-pham-goi-y",
    component: <ProductSuggest />,
    exact: false,
  },
  {
    path: "/ket-qua-soi-da",
    component: <Result />,
    exact: false,
  },
  {
    path: "/ket-qua-loi",
    component: <ErrorScanSkin />,
    exact: false,
  },
];

export default routes;
