export default function BoxAdvice() {
  return (
    <>
      <div className="box-advice">
        <p>
          Hãy nhớ đặt lịch thăm khám thường xuyên với bác sĩ da liễu của bạn để
          được kiểm tra da toàn diện
        </p>
      </div>
    </>
  );
}
