import { useState, useRef, useCallback, useEffect } from "react";
import React from "react";
import "./pagechooseimg.css";
import { BsFillCameraFill } from "react-icons/bs";
import { IoImagesOutline } from "react-icons/io5";
import PageChooseColor from "../PageChooseColor";
import Webcam from "react-webcam";
import LoadingImage from "../../View/LoadingImage";
import { useNavigate, useLocation, Link } from "react-router-dom";
const videoConstraints = {
  width: 450,
  height: 450,
  facingMode: "user",
};
function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

function PageChooseImg() {
  const navigate = useNavigate();
  const [openCamera, setOpenCamera] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const checkDevice = detectMob();
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [isLoading]);

  const webcamRef = useRef(null);
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);
  const returnCapture = () => {
    let check = detectMob();

    const cameraMobi = document.getElementById("cameraMobi");
    setOpenCamera(true);
    if (check) {
      if (cameraMobi) {
        cameraMobi.click();
      }
    }
  };
  useEffect(() => {
    return () => {
      imgSrc && URL.revokeObjectURL(imgSrc.preview);
    };
  }, [imgSrc]);

  const handlePreviewFileImg = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function () {
      setImgSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const handleTakePhoto = (e) => {
    if (e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = function () {
        setImgSrc(reader.result);
      };
      reader.readAsDataURL(file);

      const inputCamera = document.getElementById("imageCamera");
      if (inputCamera) {
        inputCamera.style.display = "none";
      }
    } else {
    }
  };

  const handleOpenCamera = (e) => {
    let check = detectMob();
    const inputCamera = document.getElementById("imageCamera");
    const cameraMobi = document.getElementById("cameraMobi");
    if (check) {
      if (inputCamera) {
        inputCamera.style.display = "block";
      }
    } else {
    }
    setOpenCamera(true);
    if (check) {
      if (cameraMobi) {
        cameraMobi.click();
      }
    }
  };
  const openInput = () => {
    const inputCamera = document.getElementById("inputtest");
    if (inputCamera) {
      inputCamera.click();
    }
  };
  return (
    <>
      <div className="wp-box">
        {imgSrc ? null : (
          <div className="wp-render container-layout">
            <div className="box-render__description"></div>
            <div className="box-render__photo">
              <img src="/assets/images/photos.JPG" alt="renderphoto" />
              {openCamera ? (
                <div className="box-render__photo-webcam">
                  <Webcam
                    audio={false}
                    height={450}
                    ref={webcamRef}
                    mirrored={true}
                    screenshotFormat="image/jpeg"
                    width={450}
                    videoConstraints={videoConstraints}
                  />
                  {imgSrc && <img src={imgSrc} />}
                </div>
              ) : null}
            </div>

            <div className="box-render__handle">
              {/* <input
                    type="file"
                    id="imageCamera"
                    capture="user"
                    accept="image/*"
                    
                  ></input> */}
              <div className="box-render__handle-item">
                <button
                  className="btn-test"
                  id="cameraMobi"
                  onClick={openInput}
                >
                  <input
                    type="file"
                    name="file"
                    hidden=""
                    id="inputtest"
                    capture="user"
                    accept="image/*"
                    onChange={handleTakePhoto}
                  ></input>
                </button>

                {openCamera ? (
                  checkDevice ? (
                    <div
                      className="box-render__handle-item"
                      onClick={returnCapture}
                    >
                      <button className="box-render__btn box-render__btn--camera">
                        <BsFillCameraFill
                          style={{
                            width: "24px",
                            height: "24px",
                            color: "#fff",
                          }}
                        />
                      </button>

                      <p>Chụp ảnh ngay</p>
                    </div>
                  ) : (
                    <div className="box-render__handle-item" onClick={capture}>
                      <button className="box-render__btn box-render__btn--camera">
                        <BsFillCameraFill
                          style={{
                            width: "24px",
                            height: "24px",
                            color: "#fff",
                          }}
                        />
                      </button>

                      <p>Chụp ảnh ngay</p>
                    </div>
                  )
                ) : (
                  <div
                    className="box-render__handle-item"
                    onClick={handleOpenCamera}
                  >
                    <button className="box-render__btn box-render__btn--camera">
                      <BsFillCameraFill
                        style={{
                          width: "24px",
                          height: "24px",
                          color: "#fff",
                        }}
                      />
                    </button>
                    <p>Chụp ảnh ngay</p>
                  </div>
                )}
              </div>
              <div className="box-render__handle-item">
                <div className="box-render__btn box-render__btn--upload">
                  <IoImagesOutline
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  <input
                    type="file"
                    id="imageUpload"
                    onChange={handlePreviewFileImg}
                    accept="image/*"
                  />
                </div>
                <p>Tải ảnh lên</p>
              </div>
            </div>
          </div>
        )}
      </div>
      {imgSrc ? <PageChooseColor imgSrc={imgSrc} /> : null}
      <div className="box-render__description-text">
        <p className="box-render__description-text-item">
          Ảnh tự chụp của bạn sẽ được phân tích và so sánh với hơn 10 000 bức
          ảnh đã được phân loại bằng một thuật toán trí tuệ nhân tạo.
        </p>
        <p className="box-render__description-text-item">
          Để có kết quả nhất quán theo thời gian, chúng tôi khuyên bạn nên chụp
          ảnh tự sướng bằng điện thoại thông minh thế hệ cuối cùng. Chúng tôi
          khuyến khích bạn sử dụng cùng một máy ảnh và các điều kiện ánh sáng
          tương tự. Vui lòng tham khảo hướng dẫn chụp ảnh tự sướng để biết các
          điều kiện phân tích tối ưu.
        </p>
      </div>
    </>
  );
}

export default PageChooseImg;
