import Banner from "./Banner";
import { useEffect, useState } from "react";
import LoadingLinear from "../../View/LoadingLinear";
import PageChooseImg from "../PageChooseImg";
import Tip from "./Modal";
import { useLocation } from "react-router-dom";
function Home() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [isLoading]);
  const location = useLocation();
  return (
    <>
      {isLoading ? (
        <LoadingLinear />
      ) : (
        <>
          <div className="container-layout">
            {!location.state?.fromError && <Tip />}
            <PageChooseImg />
          </div>
        </>
      )}
    </>
  );
}

export default Home;
