export const ENV = "production"; //dev | production | staging

const BASE_URL_HITEK_PRODUCTION = "https://api.vbeauty.com.vn/"; //yarn build && firebase deploy --only hosting:vbs-scan-skin
const BASE_URL_HITEK_DEVELOPMENT = "https://vbeauty-dev.hitek.com.vn/"; // yarn build && firebase deploy --only hosting:vbs-scan-skin-dev
const BASE_URL_HITEK_STAGING = "https://vbeauty-staging.hitek.com.vn/"; // yarn build && firebase deploy --only hosting:vbs-scan-skin-staging

export const getBaseUrl = (environment) => {
  if (environment === "dev") {
    return BASE_URL_HITEK_DEVELOPMENT;
  } else if (environment === "production") {
    return BASE_URL_HITEK_PRODUCTION;
  } else if (environment === "staging") {
    return BASE_URL_HITEK_STAGING;
  } else {
    return BASE_URL_HITEK_DEVELOPMENT;
  }
};

export const apiConfig = {
  BASE_URL: "https://hitech-demo.tikitech.vn/",
  // BASE_URL: "http://192.168.1.5:3014/",
  BASE_URL_SKINCARE: "https://portal.applamdep.com/",
  BASE_URL_HITEK: getBaseUrl(ENV), //Change url when build
  SKIN_CARE: "api/skin/request/hitek",
  GET_OVERVIEW: "get-tu-van-tong-quan",
  GET_CONCLUDED: "get-ket-luan-chi-tiet",
  HISTORY_SCAN_SKIN: "api/v1/history_scan_skin",
  USER_REQUEST_VOUCHER: "api/v1/user_request_voucher",
  GET_LIST_PRODUCT_RECOMMENDATION: "api/v1/recommendation_products/list",
  UPDATE_HISTORY_SCAN_SKIN: "api/v1/history_scan_skin",
  EP_GENERAL_ADVICE_INFO: "api/v1/history_scan_skin/general_advice_information",
  EP_DETAIL_CONCLUSION_INFO:
    "api/v1/history_scan_skin/detailed_conclusion_information",
  SKIN_CARE_HITEK: "api/v1/soida/submit",
};
