function BoxRecommend() {
  return (
    <>
      <div className="box-title-recommend">
        <div className="title-recommend__center">
          <p>Gợi ý từ chuyên gia</p>
          <img src="/assets/images/start-direct.png" alt="" />
        </div>
        <div className="title-recommend__strong">
          GỢI Ý CHĂM SÓC DA CHO BẠN ĐƯỢC CHUYÊN DA DA LIỄU ĐỀ XUẤT
        </div>
      </div>
    </>
  );
}

export default BoxRecommend;
