import { useState, useEffect } from "react";
import "./pagechoosecolor.css";
import LoadingLinear from "../../View/LoadingLinear";
import { apiConfig } from "../../../settings/constants";
import { Link, useNavigate } from "react-router-dom";
import LoadingImage from "../../View/LoadingImage";
import { useUser } from "../../../hooks/useUser";
import callApi from "../../../utils/callApi";
const flickityOptions = {
  initialIndex: 0,
  cellAlign: "left",
  fullscreen: true,
  lazyLoad: 2,
  pageDots: false,
  dots: false,
  // contain: true,
};

function PageChooseColor({ imgSrc }) {
  let userId = useUser();

  const [isLoadingSkincare, setIsLoadingSkincare] = useState(false);
  const company_id = "62bd68cb5775c602e77e5d97";

  const [loadingChooseColor, setLoadingChooseColor] = useState(false);
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();
  const skinCare = async () => {
    setIsLoadingSkincare(true);
    await callApi(apiConfig.SKIN_CARE_HITEK, "POST", {
      image_base64: imgSrc.split(",")[1],
      company_id,
    })
      .then(async (res) => {
        if (res.data.is_success) {
          if (res.data?.data) {
            const btn = document.getElementById("hrefSkinCare");
            await historySkin(res.data.data);
            sessionStorage.setItem("_t", JSON.stringify(res.data.data));
            setIsLoadingSkincare(false);
          } else {
            navigate("/ket-qua-loi", { replace: false });
          }
        } else {
          // window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        // window.location.reload();
      });
  };

  //Save history skin
  const historySkin = async (data) => {
    const bodyReqHistory = {
      phoneNumber: "xxx",
      name: "xxx",
      data: data?.data || null,
      image_base64: imgSrc.split(",")[1],
      businessDate: new Date(),
      user_id: userId ? userId : undefined,
    };
    await callApi(apiConfig.HISTORY_SCAN_SKIN, "POST", bodyReqHistory)
      .then((res) => {
        if (res.status === 200) {
          let idHistory = res.data?.data?.historyId;
          if (idHistory) {
            // set to sessionStorage
            sessionStorage.setItem("_idHistory", idHistory);
          }
          if (res.data?.status == 200) {
            setTimeout(() => {
              navigate("/ket-qua-soi-da", {
                state: {
                  imageUrl: res.data?.data?.image,
                },
                replace: false,
              });
            }, 1000);
          } else {
            navigate("/ket-qua-loi", { replace: false });
          }

          // setHistory([...res.data?.data]);
        }
      })
      .catch((err) => {
        // console.log(err);
        // window.location.reload();
      });
  };

  //Called when click button
  const callApiAnalytics = async () => {
    await skinCare();
    // historySkin();
  };
  return (
    <>
      <div style={{ display: isLoadingSkincare ? "block" : "none" }}>
        <LoadingImage />
      </div>
      <div className="wp-box">
        <div className="wp-render container-layout">
          <div className="wp-render__choose-color">
            <div className="box-render__photo">
              {/* {imageMakeup ? (
                    <img
                      src={photo}
                      alt="photo"
                      width="450"
                      height="450"
                      style={{ borderRadius: "40%" }}
                    />
                  ) : ( */}
              <img src={imgSrc} alt="renderphoto" />

              <Link id="hrefSkinCare" to="/ket-qua-soi-da"></Link>
              {/* )} */}
              {loadingChooseColor ? (
                <div className="box-render__overlay">
                  <div className="spinner-container wp-loading">
                    <div className="spinner-loader"></div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-center">
        <button
          className="box-render__btn-analysis"
          onClick={() => callApiAnalytics()}
        >
          Tải ảnh lên và phân tích
        </button>
      </div>
    </>
  );
}

export default PageChooseColor;
