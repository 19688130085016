import React from "react";
import { useNavigate } from "react-router-dom";

function ErrorScanComp() {
  const navigate = useNavigate();

  const handleReturnHome = () => {
    navigate(-1, {
      replace: false,
      state: {
        fromError: true,
      },
    });
  };
  return (
    <div className="container-layout">
      <div className="wp-box">
        <div className="wp-render container-layout">
          <div className="box-render__description"></div>
          <div className="box-render__photo">
            <img src="/assets/images/photos.JPG" alt="renderphoto" />
          </div>
        </div>
      </div>

      <div class="tip_content" style={{ margin: 0, width: "100%" }}>
        <span class="title">
          Xin lỗi bạn, dường như đã có lỗi xảy ra với ảnh của bạn, vui lòng thử
          lại theo hướng dẫn sau để có một bức ảnh hoàn hảo nhất.
        </span>
        <ol class="body">
          <li>Giữ chặt đIện thoạI trong khi chụp</li>
          <li>Tẩy trang &amp; làm sạch da trước khi sử dụng ứng dụng</li>
          <li>Cột/búI tóc lên cao và cởI mắt kính (nếu có)</li>
          <li>
            Kiểm tra ánh sáng của phòng sử dụng camera trước để phân tích làn da
            bạn
          </li>
        </ol>
        <div class="button-hide">
          <button type="button" onClick={handleReturnHome}>
            Thử lại ngay
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorScanComp;
