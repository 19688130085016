import { useEffect } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import ResultComponent from "../../components/Router/Result";
import Banner from "../../components/Router/Home/Banner";

function Result() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });
  return (
    <>
      <div style={{ minHeight: "100vh" }}>
        <Banner />
        <Header />
        <ResultComponent />
      </div>
      {/* <Footer /> */}
    </>
  );
}
export default Result;
