import * as React from "react";

export function useUser() {
  let userId = sessionStorage.getItem("userId");
  if (!userId) {
    userId = undefined;
  } else {
    userId = JSON.parse(userId);
  }

  return userId;
}
