
import './loadingImage.scss';
export default function LoadingImage() {
  return (
    <>
      <div className="modal-loading">
        <div className="box-loading-img">
          <img
            src="/assets/images/loading.svg"
            alt="loading"
            className="loading-image"
          />
        </div>
      </div>
    </>
  );
}
