import { _t } from "../Result/data";
import axios from "axios";
import { apiConfig } from "../../../settings/constants";
import { useState, useEffect } from "react";
import Flickity from "react-flickity-component";
import "./productSuggest.scss";
import BoxRecommend from "./boxRecommend";
import BoxAdvice from "./boxAdvice";
import Banner from "../ProductSuggest/banner";
import FormGiveAway from "./formGiveAway";
import SuccessGift from "./successGift";
import callApi from "../../../utils/callApi";
const flickityOptions = {
  initialIndex: 0,
  lazyLoad: true,
  cellAlign: "left",
};
export default function ProductSuggest() {
  const [products, setProducts] = useState([]);
  const hintResultOut = _t?.facedata?.hintResult;

  useEffect(() => {
    document.title = "Sản phẩm gợi ý";
    getData();
  }, []);
  const getData = async () => {
    const bodyReqProduct = {
      filter: hintResultOut ? hintResultOut : null,
    };
    await callApi(
      apiConfig.GET_LIST_PRODUCT_RECOMMENDATION,
      "POST",
      bodyReqProduct
    )
      .then((res) => {
        console.log("resProduct", res?.data?.data);
        if (res.data.status === 200) {
          setProducts(res?.data?.data);
        }
      })
      .catch((message) => {
        // alert(message);
        // window.location.reload();
      });
  };
  const renderData = (data) => {
    var itemRender = null;
    itemRender = data?.map((item, i) => {
      var itemChild = null;
      var dataProduct = item[Object.keys(item)];
      console.log(dataProduct);
      let title = dataProduct.title;
      return (
        <div className="box-list">
          {dataProduct &&
          dataProduct.list_product &&
          dataProduct.list_product.length > 0 ? (
            <div class="title">
              <p>{title}</p>
            </div>
          ) : null}
          {dataProduct &&
          dataProduct.list_product &&
          dataProduct.list_product.length > 0 ? (
            <Flickity
              className={"list"} // default ''
              elementType={"div"} // default 'div'
              options={flickityOptions} // takes flickity options {}
              disableImagesLoaded={false} // default false
              reloadOnUpdate // default false
              static // default false
              autoPlay={true}
            >
              {dataProduct.list_product.map((pr, index) => {
                return (
                  <div class="item" key={index}>
                    <a href={pr.linkdetail} target="_blank">
                      <div
                        class="box-img"
                        style={{
                          background: `url(${
                            pr.image_link ||
                            "/assets/images/no-product-image.png"
                          } )`,
                        }}
                      ></div>
                      <div class="name">{pr.title}</div>
                    </a>
                  </div>
                );
              })}
            </Flickity>
          ) : null}
        </div>
      );
    });
    return itemRender;
  };
  return (
    <>
      <div className="container-layout">
        <BoxRecommend />
        <div className="page-product-suggest">
          {products && products.length > 0 ? (
            renderData(products)
          ) : (
            <div className="text-center">
              <h6 className="not-found-product pd">Chưa có sản phẩm!</h6>
            </div>
          )}
        </div>
        <BoxAdvice />
        <Banner />
        <FormGiveAway />
        <SuccessGift />
      </div>
    </>
  );
}
