
import React, { useEffect } from "react";

import { gsap } from "gsap";
import Flickity from "react-flickity-component";

export default function Banner() {
  const flickityOptions = {
    initialIndex: 0,
  };
  return (
    <>
      <div className="banner-layout">
        <div className="container-layout height">
          <div className="flex">
            <div className="banner-img">
              <Flickity
                className={""} // default ''
                elementType={"div"} // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                static // default false
                autoPlay={true}
              >
                <img
                  className="banner-img-effect"
                  src="/assets/images/Banner_KetQuaSoiDa_01_600x113.jpg"
                  alt=""
                />
                <img
                  className="banner-img-effect"
                  src="/assets/images/Banner_KetQuaSoiDa_02_600x113.jpg"
                  alt=""
                />
                <img
                  className="banner-img-effect"
                  src="/assets/images/Banner_KetQuaSoiDa_03_600x113.jpg"
                  alt=""
                />
              </Flickity>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
