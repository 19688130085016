import { useEffect } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Home from "../../components/Router/Home";
import Banner from "../../components/Router/Home/Banner";
import { useLocation, useParams } from "react-router-dom";
function HomeRouter() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  const params = useParams();

  useEffect(() => {
    if (params) {
      sessionStorage.setItem("userId", JSON.stringify(params.user_id || ""));
    }
  }, [params]);

  return (
    <>
      <Banner />
      <Header />
      <div style={{ minHeight: "100vh" }}>
        <Home />
      </div>
      {/* <Footer /> */}
    </>
  );
}
export default HomeRouter;
