import "./header.scss";
import React from "react";
import Banner from "../../Router/Home/Banner";
import { COLOR } from "../../../constant/style";
import { IoArrowBackOutline } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  console.log(location);
  console.log(params);
  return (
    <>
      <div className="header-bg">
        <div className="container-layout">
          <div className="header">
            {location.key !== "default" && (
              <div className="header__back" onClick={() => navigate(-1)}>
                <div className="back__box">
                  <IoArrowBackOutline className="back__icon" />
                </div>
              </div>
            )}
            <div className="header__title">
              <span className="title--text-1">Soi da</span>
              <span className="title--text-2">Online</span>
              <span className="title--text-3">ai</span>
            </div>
            <div className="header__sologan">
              <svg
                width="21"
                height="8"
                viewBox="0 0 21 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="1.25"
                  x2="20.625"
                  y2="1.25"
                  stroke={COLOR.MAIN_COLOR}
                  stroke-width="1.5"
                />
                <line
                  y1="3.75"
                  x2="20.625"
                  y2="3.75"
                  stroke={COLOR.MAIN_COLOR}
                  stroke-width="1.5"
                />
                <line
                  y1="6.25"
                  x2="20.625"
                  y2="6.25"
                  stroke={COLOR.MAIN_COLOR}
                  stroke-width="1.5"
                />
              </svg>
              <span>Ứng dụng soi da với công nghệ AI hiện đại *</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
