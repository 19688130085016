import { useEffect } from "react";
import Header from "../Layout/Header";
import Banner from "../../components/Router/Result/banner";
import ErrorScanComp from "../../components/Router/ErrorScanComp";
function ErrorScanSkin() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });
  return (
    <>
      <div style={{ minHeight: "100vh" }}>
        <Banner />
        <Header />
        <ErrorScanComp />
      </div>
      {/* <Footer /> */}
    </>
  );
}
export default ErrorScanSkin;
