import { apiConfig } from "../../../settings/constants";
import axios from "axios";
import { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import callApi from "../../../utils/callApi";

export default function FormGiveAway() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [info, setInfo] = useState([]);
  const [request, setRequest] = useState(false);
  const [statusRequest, setStatusRequest] = useState(false);
  const [errPhone, setErrPhone] = useState("Số điện thoại không đúng");
  const [errName, setErrName] = useState("Văn bản tối thiểu 3 kí tự");
  // const errName = document.getElementById("errName");
  // const errPhoneNum = document.getElementById("errPhone");
  const callApiUpdateHistory = async () => {
    const HistoryId = sessionStorage.getItem("_idHistory")
      ? sessionStorage.getItem("_idHistory")
      : "";
    const _t = sessionStorage.getItem("_t")
      ? JSON.parse(sessionStorage.getItem("_t"))
      : {};

    await callApi(apiConfig.UPDATE_HISTORY_SCAN_SKIN, "PUT", {
      Name: name,
      Phone: phone,
      email: email,
      businessDate: new Date(),
      data: _t,
      HistoryId: HistoryId,
    })
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const userReqVoucher = async (click = false) => {
    const bodyReqForm = {
      phoneNumber: phone,
      name: name,
      email: email,
      businessDate: new Date(),
    };
    await callApi(apiConfig.USER_REQUEST_VOUCHER, "POST", bodyReqForm)
      .then((res) => {
        if (res.data.status === 200) {
          if (!click) {
            // click === false(ko nhấn button)
            setInfo([...res.data]);
            const errorPhoneElement = document.getElementById("errPhone");
            if (errorPhoneElement) {
              errorPhoneElement.style.display = "none";
            }
          } else {
            setStatusRequest(true);
          }
        } else {
          const errorPhoneElement = document.getElementById("errPhone");
          if (!click) {
            if (errorPhoneElement) {
              errorPhoneElement.style.display = "block";
            }
          } else {
            setStatusRequest(false);
          }
        }

        callApiUpdateHistory();
      })
      .catch((message) => {
        callApiUpdateHistory();

        if (click) {
          setRequest(false);
        }
      });
  };

  const onChangePhoneNumber = (e) => {
    // if (e.target.value) {
    //   const phoneno = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
    //   const phoneTest = phoneno.test(e.target.value);
    //   if (phoneTest) {
    //     // userReqVoucher();
    //   }
    // }
    setPhone(e.target.value);
  };
  const onChangeEmail = (e) => {
    // if (e.target.value) {
    //   const email =
    //     /^[a-z][a-z0-9_\.]{5,32}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/gm;
    //   const emailTest = email.test(e.target.value);
    //   if (emailTest) {
    //     // userReqVoucher();
    //   }
    // }
    setEmail(e.target.value);
  };
  const handleSubmit = () => {
    const fullName = document.getElementById("name");
    const phoneNumber = document.getElementById("phone");
    const errName = document.getElementById("errName");
    const errPhoneNum = document.getElementById("errPhone");
    const errEmail = document.getElementById("errEmail");

    if (fullName && phoneNumber) {
      const phoneno = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
      const phoneTest = phoneno.test(phone);
      const emailReg =
        /^[a-z][a-z0-9_\.]{5,32}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/gm;
      console.log(email);
      const emailTest = emailReg.test(email);
      console.log(emailTest);
      var index = 0;
      if (name !== "" && name.length > 3) {
        errName.style.display = "none";
      } else {
        errName.style.display = "block";
        index++;
      }
      if (phone !== "" && phoneTest) {
        errPhoneNum.style.display = "none";
      } else {
        errPhoneNum.style.display = "block";
        index++;
      }
      if (email === "") {
        errEmail.style.display = "block";
        errEmail.innerText = "Trường này không được để trống";
        index++;
      } else if (!emailTest) {
        errEmail.style.display = "block";
        errEmail.innerText = "Email không đúng định dạng!";
        index++;
      } else {
        errEmail.innerText = "";
        errEmail.style.display = "none";
      }

      if (index === 0) {
        setRequest(true);
        setTimeout(() => {
          userReqVoucher(true);
          setStatusRequest(true);
        }, 2000);
      }
    }
  };
  return (
    <>
      <div className="wp-form-giveaway">
        <button className="giveaway__btn giveaway__btn--size-l">
          {/* <img src="/assets/images/arrow.png" className="img-arrow" alt="" /> */}
          <p>Tham gia điền form nhận quà hấp dẫn</p>
        </button>
        <div
          className="form-group"
          style={{ display: statusRequest ? "none" : "block" }}
        >
          <div className="row">
            <div className="col">
              <label className="form-label">
                Họ và tên <span>(*)</span>
              </label>
              <input
                value={name}
                type="text"
                id="name"
                className="form-control"
                placeholder="Nhập họ và tên"
                onChange={(e) => setName(e.target.value)}
              />
              <span
                className="errName errStatus"
                id="errName"
                style={{ fontSize: "16px" }}
              >
                {errName}
              </span>
            </div>
            <div className="col">
              <label className="form-label">
                Số điện thoại <span>(*)</span>
              </label>
              <input
                value={phone}
                type="text"
                id="phone"
                className="form-control"
                placeholder="Nhập số điện thoại"
                onChange={(e) => onChangePhoneNumber(e)}
              />
              <span
                className="errPhone errStatus"
                id="errPhone"
                style={{ fontSize: "16px" }}
              >
                {errPhone}
              </span>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <label className="form-label">
                Địa chỉ email <span>(*)</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Địa chỉ email"
                value={email}
                onChange={onChangeEmail}
              />
              <span
                className="errEmail errStatus"
                id="errEmail"
                style={{ fontSize: "16px" }}
              ></span>
            </div>
          </div>
          <div style={{ display: request ? "none" : "block" }}>
            <button
              className="giveaway__btn giveaway__btn--size-s"
              onClick={() => handleSubmit()}
            >
              <img
                src="/assets/images/arrow.png"
                className="img-arrow"
                alt=""
              />
              <p>Nhận quà</p>
            </button>
          </div>
          <div
            style={{ display: request ? "flex" : "none", alignItems: "center" }}
          >
            <button className="giveaway__btn giveaway__btn--size-s">
              <Spinner
                style={{ marginRight: "6px" }}
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <p>Đang gửi yêu cầu</p>
            </button>
          </div>
        </div>
        <div
          style={{ display: statusRequest ? "block" : "none" }}
          className="success-gift"
        >
          <h2>CHÚNG TÔI VỪA GHI NHẬN THÔNG TIN CỦA BẠN</h2>
          <p>Sẽ liên hệ lại với bạn trong thời gian sớm nhất</p>
        </div>
      </div>
    </>
  );
}
