import { apiConfig, ENV } from "../settings/constants";
import axios from "axios";

const defaultHeader = {
  "Content-Type": "application/json",
  env: ENV,
  platform: "scanskin",
};
const devHeader = {
  ...defaultHeader,
  localhost: "scanskin",
};
const callApi = (endpoint, method = "GET", data = null) => {
  return axios({
    url: `${apiConfig.BASE_URL_HITEK}${endpoint}`,
    method,
    data,

    headers: process.env.NODE_ENV === "development" ? devHeader : defaultHeader,
  });
};

export default callApi;
